<template>
  <base-table :title="title" :items="events" :headers="headers" v-bind="tableProps" @deleteItem="deleteEvent">
    <template v-slot:[`name`]="{ item }">
      <router-link :to="{ name: 'Event', params: { id: item.id } }">{{ item.name }} </router-link>
    </template>
    <template v-slot:[`time`]="{ item }">
      <span class="text-no-wrap">{{ moment(item.start_timestamp).format("H:mm") }}-{{ moment(item.end_timestamp).format("H:mm") }}</span>
    </template>
    <template v-slot:form="{ selected_id, close, duplicating }">
      <event-form
        :event_id="selected_id"
        :baseItem="baseItem"
        @cancel="close"
        :duplicating="duplicating"
        @save="(event) => eventUpdated(event, close)"
        @created="(event) => eventCreated(event, close)" />
    </template>
  </base-table>
</template>

<script>
export const EVENTS_TABLE_FRAGMENT = gql`
  fragment eventsTable on Event {
    id
    name
    start_timestamp
    end_timestamp
    poster: file(name: "poster") {
      id
      name
      link
    }
  }
`;

import BaseTable from "./BaseTable.vue";
import EventForm from "../forms/EventForm.vue";
import gql from "graphql-tag";
import moment from "moment";

export default {
  components: { BaseTable, EventForm },
  name: "EventsTable",
  props: {
    title: String,
    events: Array,
    baseItem: { type: Object, default: () => ({}) },
    tableProps: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      headers: [
        { value: "name", text: this.$t("event.name"), type: "template" },
        { value: "start_timestamp", text: this.$t("event.date"), type: "date" },
        { value: "time", text: this.$t("event.time"), type: "template" },
        { value: "poster", text: this.$t("event.poster"), type: "file" },
      ],

      moment,
    };
  },

  methods: {
    eventUpdated(event, close) {
      this.$emit("eventUpdated", event);
      close();
    },

    eventCreated(event, close) {
      this.$emit("eventCreated", event);
      close();
    },

    deleteEvent(event) {
      this.$emit("deleteEvent", event);
    },
  },
};
</script>
