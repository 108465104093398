<template>
  <v-container>
    <v-row>
      <v-col md="4" cols="12">
        <edit-card v-if="course" :info="info" :item="course" rounded="lg" model="course">
          <template v-slot:title>
            <back-button :to="backRoute" :text="course.name" />
          </template>
          <template v-slot:form="{ close }">
            <course-form @save="close" @cancel="close" :course_id="$route.params.id" />
          </template>
          <template #bottom>
            <enroll-button :enrollable="course"></enroll-button>
            <payment-link-button :payable="course"></payment-link-button>
          </template>
        </edit-card>
        <v-skeleton-loader type="card" v-else rounded="lg" />
      </v-col>

      <v-col md="8" cols="12">
        <v-skeleton-loader type="table" v-if="loading" rounded="lg" />

        <v-card v-if="!loading" class="overflow-hidden mb-4" rounded="lg">
          <events-table
            :events="course.events"
            :title="$tc('models.event', 2)"
            :baseItem="{ course, organizer: course.organizer, acknowledgment_ratio: 1 }"
            :tableProps="{ viewOnly: !course.canCreateEvents }"
            @eventCreated="eventCreated"
            @deleteEvent="deleteEvent"
          />
        </v-card>
        <v-card
          v-if="!loading && course.canEnrollAny && course.enrollments_enabled"
          class="overflow-hidden mb-4"
          rounded="lg"
        >
          <enrolled-table :enrolled="enrolledStudentYears" :baseItem="{ enrollable: course }" />
        </v-card>
        <v-card
          v-if="!loading && course.canViewPayments && course.has_payments"
          class="overflow-hidden mb-4"
          rounded="lg"
        >
          <v-skeleton-loader type="table" v-if="$apollo.queries.payable.loading" rounded="lg" />
          <payments-table
            :payable="payable"
            :title="$tc('models.payment', 2)"
            :tableProps="{ showTotal: true }"
            :baseItem="{ course_id: course.id, type: 'course_enrollment' }"
            v-else
          >
          </payments-table>
        </v-card>

        <my-review
          class="mb-4"
          v-if="!loading && course.canCreateReviews"
          :review="course.myReview"
          :reviewable="course"
          @created="reviewCreated"
          @deleted="reviewDeleted"
        />
        <reviews-list v-if="!loading" :reviews="course.reviews" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { addToCacheArray, deleteModel, removeFromCacheArray } from "../apollo/helpers";
import BackButton from "../components/BackButton.vue";
import gql from "graphql-tag";
import EditCard from "../components/base/EditCard.vue";
import CourseForm from "../components/forms/CourseForm.vue";
import ReviewsList from "../components/education/reviews/ReviewsList.vue";
import { REVIEW_FRAGMENT } from "../components/education/reviews/Review.vue";
import MyReview from "../components/education/reviews/MyReview.vue";
import EventsTable, { EVENTS_TABLE_FRAGMENT } from "../components/tables/EventsTable.vue";
import EnrolledTable, { ENROLLABLE_ENROLLED_FRAGMENT } from "../components/tables/EnrolledTable.vue";
import EnrollButton, { ENROLL_BUTTON_FRAGMENT } from "../components/education/enrollments/EnrollButton.vue";
import PaymentsTable, { PAYABLE_PAYMENTS_TABLE_FRAGMENT } from "../components/tables/PaymentsTable.vue";
import PaymentLinkButton, { PAYMENT_LINK_FRAGMENT } from "../components/education/payments/PaymentLinkButton.vue";

const COURSE_ENROLLED_QUERY = gql`
  query Course($id: ID!) {
    course(id: $id) {
      id
      ...enrolledTable
    }
  }
  ${ENROLLABLE_ENROLLED_FRAGMENT}
`;

const COURSE_PAYMENTS_QUERY = gql`
  query Course($id: ID!) {
    course(id: $id) {
      id
      ...payablePaymentsTable
    }
  }
  ${PAYABLE_PAYMENTS_TABLE_FRAGMENT}
`;

const COURSE_QUERY = gql`
  query Course($id: ID!) {
    course(id: $id) {
      id
      name
      organizer
      has_payments
      ...paymentLink
      enrollments_enabled
      reports_enabled
      shared

      canUpdate
      canCreateEvents
      canEnrollAny
      canViewPayments
      canCreateReviews

      canEnroll

      ...enrollButton

      rating
      eventsRating

      tag {
        id
        name
      }
      year {
        id
        name
      }
      manager {
        id
        complete_name
      }
      events {
        ...eventsTable
      }
      area {
        id
        name
      }
      sharedWith {
        id
        name
      }
      reviews {
        ...review
      }
      myReview {
        ...review
      }
    }
  }
  ${REVIEW_FRAGMENT}
  ${EVENTS_TABLE_FRAGMENT}
  ${ENROLL_BUTTON_FRAGMENT}
  ${PAYMENT_LINK_FRAGMENT}
`;

export default {
  components: {
    BackButton,
    EditCard,
    CourseForm,
    ReviewsList,
    MyReview,
    EventsTable,
    EnrolledTable,
    EnrollButton,
    PaymentLinkButton,
    PaymentsTable,
  },

  data() {
    return {
      info: [
        { field: "organizer", cols: 12 },
        {
          field: "manager.complete_name",
          label: this.$t("course.manager"),
          cols: 12,
        },
        { field: "tag.name", label: this.$t("tag.name"), cols: 12 },
        { divider: true, cols: 12 },
        { field: "rating", cols: 12, type: "rating" },
        { field: "eventsRating", cols: 12, type: "rating" },
      ],
      enrolling: false,
      payments: [],
      enrolledStudentYears: [],
    };
  },

  apollo: {
    course: {
      query: COURSE_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    payable: {
      query() {
        return COURSE_PAYMENTS_QUERY;
      },
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update: (data) => data.course,
      skip() {
        return !this.course || !this.course.canViewPayments;
      },
    },
    enrolledStudentYears: {
      query() {
        return COURSE_ENROLLED_QUERY;
      },
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update: (data) => data.course.enrolledStudentYears,
      skip() {
        return !this.course || !this.course.canEnrollAny;
      },
    },
  },

  computed: {
    loading() {
      return this.$apollo.queries.course.loading;
    },

    query() {
      return { query: COURSE_QUERY, variables: { id: this.$route.params.id } };
    },

    backRoute() {
      return {
        name: "Education",
        query: {
          area_id: this.course?.area.id,
          year_id: this.course?.year.id,
          tag_id: this.course?.tag?.id ?? -1,
        },
      };
    },
  },

  methods: {
    reviewDeleted({ id }) {
      const store = this.$apollo.provider.defaultClient;

      removeFromCacheArray(store, {
        query: {
          query: COURSE_QUERY,
          variables: { id: this.$route.params.id },
        },
        queryField: "course.reviews",
        id,
      });
    },

    reviewCreated(newElement) {
      const store = this.$apollo.provider.defaultClient;
      addToCacheArray(store, {
        query: {
          query: COURSE_QUERY,
          variables: { id: this.$route.params.id },
        },
        queryField: "course.reviews",
        newElement,
        orderBy: { created_at: "desc" },
      });
    },

    eventCreated(newElement) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: COURSE_QUERY,
          variables: {
            id: this.$route.params.id,
          },
        },
        queryField: "course.events",
        newElement,
        unshift: true,
      });
    },

    deleteEvent({ id }) {
      deleteModel(this.$apollo, {
        id,
        query: this.query,
        queryField: "course.events",
        model: "Event",
      });
    },
  },
};
</script>

<style></style>
