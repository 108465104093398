<template>
  <base-form
    model="course"
    @save="course_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.course.loading"
    :currentValue="course"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import { getEnrollableFields, ENROLLABLE_FRAGMENT } from "./enrollable";
import { getPayableFields, PAYABLE_FRAGMENT } from "./payable";

const COURSE_FRAGMENT = gql`
  fragment course on Course {
    id
    name
    ...enrollableForm
    ...payableForm
    organizer
    cost
    supplier
    shared
    acknowledgment_threshold
    reports_enabled
    description
    manager {
      id
      complete_name
    }
    tag {
      id
      name
      color
    }
    year {
      id
    }
    area {
      id
    }
    sharedWith {
      id
      name
    }
    poster: file(name: "poster") {
      id
      name
      link
    }
  }
  ${ENROLLABLE_FRAGMENT}
  ${PAYABLE_FRAGMENT}
`;

export default {
  components: { BaseForm },
  name: "CourseForm",
  props: {
    course_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  apollo: {
    course: {
      query: gql`
        query Course($id: ID!) {
          course(id: $id) {
            ...course
          }
        }
        ${COURSE_FRAGMENT}
      `,
      variables() {
        return { id: this.course_id };
      },
      skip() {
        return this.course_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      course: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
        },
        { name: "organizer" },
        { name: "supplier" },
        {
          name: "cost",
          type: "number",
          step: 0.01,
          bind: { "append-icon": "mdi-currency-eur" },
        },
        {
          name: "manager",
          type: "query",
          path: "area.managers",
          query: gql`
            query Area($area_id: ID!) {
              area(id: $area_id) {
                id
                managers {
                  id
                  name
                  surname
                }
              }
            }
          `,
          variables: () => {
            return {
              area_id: this.course_id ? this.course.area.id : this.baseItem.area.id,
            };
          },
          skip: () => this.course_id && this.$apollo.queries.course.loading,
          label: this.$t("course.manager"),
          text: ({ name, surname }) => `${surname} ${name}`,
          bind: {
            clearable: true,
          },
          cols: 12,
        },
        ...this.getPayableFields(),
        {
          name: "reports_enabled",
          type: "checkbox",
        },
        ...this.getEnrollableFields(),
        {
          name: "acknowledgment_threshold",
          type: "number",
          bind: {
            clearable: true,
          },
        },
        {
          name: "tag",
          type: "query",
          path: "year.tags",
          query: gql`
            query Tags($year_id: ID!) {
              year(id: $year_id) {
                id
                tags {
                  id
                  name
                  color
                }
              }
            }
          `,
          variables: () => {
            return {
              year_id: this.course_id ? this.course.year.id : this.baseItem.year.id,
            };
          },
          skip: () => this.course_id && this.$apollo.queries.course.loading,
          label: this.$t("tag.name"),
          bind: {
            clearable: true,
          },
        },
        {
          name: "sharedWith",

          type: "query",
          path: "area.siblingAreas",
          query: gql`
            query Area($area_id: ID!) {
              area(id: $area_id) {
                id
                siblingAreas {
                  id
                  name
                }
              }
            }
          `,
          variables: () => {
            return {
              area_id: this.course_id ? this.course.area.id : this.baseItem.area.id,
            };
          },
          skip: () => this.course_id && this.$apollo.queries.course.loading,
          label: this.$t("course.sharedWith"),
          bind: {
            clearable: true,
            multiple: true,
          },
        },
        { name: "poster", type: "file" },
        { name: "description", type: "textarea", cols: 12 },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Course",
            fragment: COURSE_FRAGMENT,
            fragment_name: "course",
          }),
          variables: {
            id: this.course_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      // eslint-disable-next-line no-unused-vars
      const { enrollments_status, ...input } = this.newValue;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Course",
            fragment: COURSE_FRAGMENT,
            fragment_name: "course",
          }),
          variables: {
            input,
          },
        })
        .then(({ data: { createCourse } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createCourse);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    getEnrollableFields,
    getPayableFields,
  },
};
</script>
